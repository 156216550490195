import { loadable } from '@change-corgi/core/react/loadable';

import type { AppRoute } from 'src/shared/routes';

import { Loading } from 'src/app/shared/routes';

export const ROUTES: readonly AppRoute[] = [
	{
		id: 'topic-sitemap',
		path: '/topic',
		component: loadable(async () => import(/* webpackChunkName: "pages/topic_sitemap" */ './index'), {
			exportName: 'TopicSitemap',
			fallback: Loading,
		}),
		frame: true,
		ssr: {
			// switch this to true to enable SSR
			enabled: false,
			seo: false,
		},
		restrictedAccess: {
			authOnly: true,
			role: 'staff',
		},
	},
];
